<template>
  <div class="container">
    <PublicHeader :totalName="$t('recharge').title1" />
    <div class="content">
      <van-list
        class="list-box"
        ref="list"
        v-model="loading"
        :finished="finished"
        :loading-text="$t('public').loadingText"
        :finished-text="$t('public').noMoreData"
        @load="getList"
      >
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="left">
            <div class="num">{{ item.Amount }} {{ item.UnitType }}</div>
            <div class="tips up">{{ item.Remark }}</div>
            <div class="dataTime">{{ item.CreateTime | getLocalTime }}</div>
          </div>
          <div
            class="right down"
            :class="{ up: item.TransferStatus === 'Fail' }"
          >
            {{ $t('statusLang')[item.TransferStatus] }}
            <div class="reason" v-if="item.TransferStatus === 'Fail'">
              {{ item.ExamineRemark }}
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeaderNew'
import transactionApi from '@/api/transaction'
export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      list: [],
      form: {
        isUpper: false,
        page: 1,
        size: 10
      },
      loading: false,
      finished: false
    }
  },
  methods: {
    async getList() {
      const res = await transactionApi.getRecords(this.form)
      let data = res.Data
      let length = data.length
      if (length != 0) {
        this.list.push.apply(this.list, data)
        this.form.page++
      }
      if (length !== this.form.size) {
        this.finished = true
      }
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding-top: 44px;
  .content {
    margin: 10px;
    border-radius: 4px;
    overflow: hidden;
    .list-box {
      height: calc(100vh - 64px);
      overflow: scroll;
    }
    .item {
      background: #17181e;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      border-bottom: 1px solid #1f253c;
      .left {
        .num {
          font-size: 16px;
          margin-bottom: 5px;
        }
        .tips {
          font-weight: bold;
        }
        .dataTime {
          margin-top: 5px;
          color: #d5d2d2;
        }
      }
      .right {
        max-width: 30%;
        text-align: right;
        .reason {
          font-size: 10px;
          margin-top: 5px;
          color: #d5d2d2;
        }
      }
    }
  }
}
</style>
